import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GetInteractionsRequest } from "../../api/interaction";
import {
  useInteractions,
  useInteractionMetadata,
} from "../../hooks/useInteraction";
import Interaction, {
  InteractionType,
} from "../../types/interaction/Interaction";
import SearchResults, { SEARCH_DEFAULT_SORT_MODEL } from "./SearchResults";
import { isEqual } from "lodash";
import SearchDrawer from "./SearchDrawer";
import { DEFAULT_SEARCH_VALUES, SearchFormValues } from "../../types/search/SearchFormValues";
import SearchFieldLabelProvider from "./context/SearchFieldLabelProvider";
import { Badge, Button } from "@mui/material";
import { MediaDrawer } from "../../components/media/MediaDrawer";
import useScreenSize from "../../hooks/useScreenSize";
import FieldLabelApiStorageProvider from "./context/FieldLabelApiStorageProvider";

/* istanbul ignore next */
export const convertToRequest = (
  formValues?: SearchFormValues,
  pageSize: number = 100,
  pageIndex: number = 0,
  sortBehavior?: GridSortModel
): GetInteractionsRequest => {

  const request: GetInteractionsRequest = {
    dnis: formValues?.dnis,
    agentName: formValues?.agentName,
    direction: formValues?.direction ?? undefined,
    phoneNumber: formValues?.phoneNumber,
    siteId: formValues?.site?.siteId ?? undefined,
    from: formValues?.from?.utc().toISOString(),
    to: formValues?.to?.utc().toISOString(),
    fromRetention: formValues?.fromRetention?.utc().toISOString(),
    toRetention: formValues?.toRetention?.utc().toISOString(),
    hasRetention: formValues?.hasRetention ?? undefined,
    minDuration: formValues?.minDuration
      ? formValues.minDuration * 1_000
      : undefined,
    maxDuration: formValues?.maxDuration
      ? formValues.maxDuration * 1_000
      : undefined,
    legalHold: formValues?.legalHold ?? undefined,
    hasMedia: formValues?.hasMedia ?? undefined,
    sourceId: formValues?.sourceId,
    extension: formValues?.extension ?? undefined,
    device: formValues?.device ?? undefined,
    interactionId: formValues?.interactionId ?? undefined,
    masterInteractionId: formValues?.masterInteractionId ?? undefined,
    group: formValues?.group,
    agentId: formValues?.agentId ?? undefined,
    skill: formValues?.skill,
    segmentId: formValues?.segmentId ?? undefined,
    tags: formValues?.tags ?? undefined,
    pageIndex,
    pageSize,
  };
  if (sortBehavior) {
    request.sortBehavior = sortBehavior;
  }
  return request;
};

export const DEFAULT_REQUEST: GetInteractionsRequest = {
  pageIndex: 0,
  pageSize: 100,
};

const getPreferredMediaWidth = (defaultMediaWidth: number): number => {
  const preferredMediaWidth = localStorage.getItem("prefMediaPlayerWidth");
  if (preferredMediaWidth) {
    return Number(preferredMediaWidth);
  }
  return defaultMediaWidth;
};

export const SEARCH_TABLE_ID = "interactions";

const Search = () => {
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [playerInteractionId, setPlayerInteractionId] = useState<string>("");
  const [sortModel, setSortModel] = useState<GridSortModel>(
    SEARCH_DEFAULT_SORT_MODEL
  );
  const [rowCount, setRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(100);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const { customerId } = useParams();
  const [searchRequest, setSearchRequest] =
    useState<GetInteractionsRequest>(DEFAULT_REQUEST);
  const { data, refetch, isRefetching, isLoading } = useInteractions(
    searchRequest,
    customerId
  );
  const prevSearchValues = useRef<SearchFormValues | undefined>();
  const [searchValues, setSearchValues] = useState<SearchFormValues>(DEFAULT_SEARCH_VALUES);
  const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);
  const [mediaDrawerOpen, setMediaDrawerOpen] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { width: screenWidth } = useScreenSize();
  const [mediaDrawerWidth, setMediaDrawerWidth] = useState(
    getPreferredMediaWidth(useScreenSize().width * 0.3)
  );
  const minMediaDrawerWidth = screenWidth * 0.2;
  const maxMediaDrawerWidth = screenWidth * 0.5;

  const { data: interactionMetadata } = useInteractionMetadata(
    customerId!,
    playerInteractionId
  );

  const mediaType: string | undefined = interactionMetadata?.interactionType as
    | InteractionType
    | undefined;

  const [filterDrawerWidth, setFilterDrawerWidth] = useState<number>(300);

  useEffect(() => {
    const newMediaDrawerWidth = Math.max(
      minMediaDrawerWidth,
      Math.min(mediaDrawerWidth, maxMediaDrawerWidth)
    );
    setMediaDrawerWidth(newMediaDrawerWidth);
  }, [screenWidth, minMediaDrawerWidth, maxMediaDrawerWidth, mediaDrawerWidth]);

  useEffect(() => {
    setMediaDrawerWidth((prevWidth) =>
      Math.max(minMediaDrawerWidth, Math.min(prevWidth, maxMediaDrawerWidth))
    );
  }, [minMediaDrawerWidth, maxMediaDrawerWidth]);

  useEffect(() => {
    if (data?.totalCount == null) return;
    setRowCount(data.totalCount);
  }, [data]);

  useEffect(() => {
    if (!isEqual(searchValues, prevSearchValues.current)) {
      setPageIndex(0);
      setSearchRequest(convertToRequest(searchValues, pageSize, 0, sortModel));
      prevSearchValues.current = searchValues;
      return;
    }
    setSearchRequest(
      convertToRequest(searchValues, pageSize, pageIndex, sortModel)
    );
    prevSearchValues.current = searchValues;
  }, [pageIndex, pageSize, searchValues, sortModel]);

  useEffect(() => {
    setInteractions(data?.interactions ?? []);
  }, [data]);

  const handlePaginationModelChange = ({
    page,
    pageSize,
  }: GridPaginationModel) => {
    setPageIndex(page);
    setPageSize(pageSize);
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    setSortModel(sortModel);
  };

  const closePlayer = () => {
    setIsPlaying(false);
    setPlayerInteractionId("");
    setMediaDrawerOpen(false);
  };

  const handleDrawer = () => {
    setOpenFilterDrawer(!openFilterDrawer);
  };

  const getFilterCount = () => {
    const actualFilters = Object.entries(searchValues).filter(([key, value]) => {
      const defaultValue = DEFAULT_SEARCH_VALUES[key as keyof SearchFormValues];
      if (value === null) {
        return false;
      }
      else if (Array.isArray(value) && Array.isArray(defaultValue)) { //I'll need this for tags eventually
        return value.length !== defaultValue.length;
      } else {
        return value !== defaultValue;
      }
    });
    return actualFilters.length;
  };  

  return (
    <FieldLabelApiStorageProvider customerId={customerId!}>
      <SearchFieldLabelProvider tableId={SEARCH_TABLE_ID}>
        <div
          style={{
            display: "flex",
            height: "100%",
            transition: "all 0.1s",
            marginLeft: openFilterDrawer ? filterDrawerWidth : "0",
            marginRight: mediaDrawerOpen ? mediaDrawerWidth : "0",
          }}
        >
          <SearchDrawer
            setSearchValues={setSearchValues}
            refetch={refetch}
            customerId={customerId!}
            closePlayer={closePlayer}
            filterDrawerWidth={filterDrawerWidth}
            setFilterDrawerWidth={setFilterDrawerWidth}
            openFilterDrawer={openFilterDrawer}
            setOpenFilterDrawer={setOpenFilterDrawer}
            handlePaginationModelChange={handlePaginationModelChange}
          />
          <div className="h-full flex flex-col p-4 w-full">
            <div>
              <Badge badgeContent={getFilterCount()} color="info">
                <Button variant="contained" size="large" onClick={handleDrawer}>
                  FILTER
                </Button>
              </Badge>
            </div>
            <SearchResults
              playerInteractionId={playerInteractionId}
              setPlayerInteractionId={setPlayerInteractionId}
              className="flex-1 flex-grow mt-2"
              results={interactions}
              customerId={customerId!}
              isLoading={isLoading || isRefetching}
              paginationModel={{ page: pageIndex, pageSize }}
              onPaginationModelChange={handlePaginationModelChange}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              rowCount={rowCount}
              tableId={SEARCH_TABLE_ID}
              refresh={() => {
                refetch();
              }}
              setMediaDrawerOpen={setMediaDrawerOpen}
              pageIndex={pageIndex}
            />

            <MediaDrawer
              mediaDrawerOpen={mediaDrawerOpen}
              playerInteractionId={playerInteractionId}
              customerId={customerId!}
              closePlayer={closePlayer}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              mediaDrawerWidth={mediaDrawerWidth}
              setMediaDrawerWidth={setMediaDrawerWidth}
              minMediaDrawerWidth={minMediaDrawerWidth}
              maxMediaDrawerWidth={maxMediaDrawerWidth}
              mediaType={mediaType}
            />
          </div>
        </div>
      </SearchFieldLabelProvider>
    </FieldLabelApiStorageProvider>
  );
};

export default Search;
