import { TypeAnimation } from "react-type-animation";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../../images/ContinuityLogo.png";

const DefaultHomePage = () => {
  const theme = useTheme();
  return (
   <div style={{
    display:"flex",
    height:"100%",
    justifyContent:"center",
    alignContent:"center",
    alignItems: "center"
   }}>
    <div>
      <div 
      style={{
        display:"flex",
        justifyContent:"center",
        alignContent:"center",
        alignItems:"center",
      }}>
        <img
          src={logo}
          alt="Continuity Replay Logo"
          loading="lazy"
          style={{
            maxHeight: 200,
            maxWidth: 200,
            width: "auto",
            height: "auto",
          }}
        />
        <Typography variant="h2" color={theme.palette.primary.main}>
          <TypeAnimation
            sequence={["CONTINUITY REPLAY"]}
            speed={20}
            style={{
              display: "block",
            }}
          />
        </Typography>
    </div>
      <Typography variant="h6" textAlign="center">Customer interaction solutions that put people first.</Typography>
        </div>
    </div>
  );
};

export default DefaultHomePage;
