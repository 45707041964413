import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import CellHoverMenu from "./CellHoverMenu";
import useTableCell from "./useTableCell";

export interface TableCellProps
  extends GridRenderCellParams<any, any, any, GridTreeNodeWithRender> {
  renderCell?: (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => React.ReactNode;
}

export default function TableCell({ renderCell, ...params }: TableCellProps) {
  const {
    handleMouseEnter,
    handleMouseLeave,
    displayCellData,
    isHoverMenuOpen,
  } = useTableCell(params, renderCell);

  return (
    <div
      className="flex space-x-2 h-full justify-center items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex-1 flex-shrink overflow-hidden min-w-0">
        {displayCellData()}
      </div>
      <CellHoverMenu isOpen={isHoverMenuOpen} {...params} />
    </div>
  );
}
