import { type GridColDef, type GridInitialState } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultsPage from "../../components/common/ResultsPage/ResultsPage";
import { useAlert } from "../../context/AlertContext";
import { useCreateSite, useEditSite, useSites } from "../../hooks/useSite";
import ErrorMessage from "../../types/feedback/ErrorMessage";
import type Site from "../../types/site/Site";
import SiteForm, { type SiteFormValues } from "./SiteForm";
import DeleteSiteDialog from "./DeleteSiteDialog";

const initialState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      siteId: false,
      customerId: false,
    },
  },
};

export type SiteAction = "Create" | "Edit";

export default function SiteList() {
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [action, setAction] = useState<SiteAction>("Create");
  const [isSiteFormOpen, setIsSiteFormOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<SiteFormValues | undefined>(
    undefined
  );
  const { customerId } = useParams();
  const { isLoading, data } = useSites(customerId);
  const createSiteMutation = useCreateSite();
  const editSiteMutation = useEditSite();
  const showAlert = useAlert();

  useEffect(() => {
    const sites = data?.sites;
    const apiErrors = data?.errors;
    if (!!apiErrors && !apiErrors?.isEmpty()) {
      const errorMessage = apiErrors.getFieldError("root.generic");
      if (errorMessage) showAlert(errorMessage, "error");
      return;
    }
    setSites(sites ?? []);
  }, [data, showAlert]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", minWidth: 200, maxWidth: 400 },
    { field: "siteId", headerName: "Site ID", minWidth: 300, maxWidth: 325 },
    {
      field: "customerId",
      headerName: "Customer ID",
      minWidth: 300,
      maxWidth: 325,
    },
  ];

  const createSite = async (data: SiteFormValues) => {
    try {
      const { site, errors } = await createSiteMutation.mutateAsync({
        customerId: customerId!,
        ...data,
      });
      if (!site) {
        const errorMap = (errors as any)?.errors as Map<string, string[]>;
    
        const generalErrorMessages = errorMap?.get('generalErrors')?.join(", ") || "Failed to create site.";
        showAlert(generalErrorMessages, "error");
        return;
      }    
      setSites((prevSites: Site[]) => {
        return [...prevSites, site];
      });
      showAlert("Site created successfully.", "success");
      setIsSiteFormOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  const editSite = async (formData: SiteFormValues) => {
    try {
      const {site, errors} = await editSiteMutation.mutateAsync({
        customerId: customerId!,
        siteId: selectedRowId!,
        ...formData,
      });
      if (!site) {
        const errorMap = (errors as any)?.errors as Map<string, string[]>;
    
        const generalErrorMessages = errorMap?.get('generalErrors')?.join(", ") || "Failed to edit site.";
        showAlert(generalErrorMessages, "error");
        return;
      }
      setSites((prevSites: Site[]) => {
        return [...prevSites.filter((s) => s.siteId !== site.siteId), site];
      });
      showAlert("Site edited successfully.", "success");
      setIsSiteFormOpen(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showAlert(error.message, "error");
      } else {
        showAlert(ErrorMessage.UNKNOWN, "error");
      }
    }
  };

  const getRowId = (row: Site) => {
    return row.siteId;
  };

  return (
    <div className="h-full flex flex-col">
      <ResultsPage
        permissionResource="sites"
        tableId="sites"
        entityName="Site"
        columns={columns}
        rows={sites}
        getRowId={getRowId}
        initialState={initialState}
        loading={isLoading}
        selectedRowId={selectedRowId}
        setSelectedRowId={setSelectedRowId}
        setCreateFormOpen={(open: boolean) => {
          setAction("Create");
          setIsSiteFormOpen(open);
        }}
        setEditFormOpen={(open: boolean) => {
          const selectedSite = sites.find(
            (site) => getRowId(site) === selectedRowId
          );
          if (selectedSite) {
            setFormValues({
              name: selectedSite.name,
            });
          }
          setAction("Edit");
          setIsSiteFormOpen(open);
        }}
        setDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <SiteForm
        action={action}
        isOpen={isSiteFormOpen}
        setIsOpen={setIsSiteFormOpen}
        createSite={createSite}
        editSite={editSite}
        formValues={formValues}
      />
      <DeleteSiteDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      />
    </div>
  );
}
