import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import FieldLabelContext, {
  FieldLabel,
  PartialFieldLabel,
} from "../../search/context/FieldLabelContext";
import { FormGroup } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAlert } from "../../../context/AlertContext";
import ResetHeadersConfirmation from "./ResetHeadersConfirmation";

export const HEADER_NAMES_FORM_ID = "header-names-form";

const mapFieldLabelToString = (fieldLabel: FieldLabel): string =>
  fieldLabel.name ?? fieldLabel.defaultName ?? "";
const mapStringToPartialFieldLabel = (value: string): PartialFieldLabel => ({
  name: value,
});

const TableHeaderConfigurator = () => {
  const form = useFormContext();
  const { handleSubmit, reset, register, formState } = form;
  const { isSubmitting, isLoading } = formState;
  const { columnNames, fieldLabels, saveFieldLabels } =
    useContext(FieldLabelContext);
  const showAlert = useAlert();
  const [initialized, setInitialized] = useState(false);
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);

  const submit = async (values: Record<string, string>) => {
    try {
      const fieldLabelRecord: Record<string, PartialFieldLabel> = {};
      Object.entries(values).forEach(([key, value]) => {
        fieldLabelRecord[key] = mapStringToPartialFieldLabel(value);
      });
      await saveFieldLabels(fieldLabelRecord);
      showAlert("Header names updated successfully.", "success");
    } catch (error) {
      console.error("Error saving field labels:", error);
      showAlert("An error occurred while saving header names.", "error");
    }
  };

  useEffect(() => {
    if (initialized || !fieldLabels || Object.keys(fieldLabels).length === 0)
      return;
    const fieldLabelRecord: Record<string, unknown> = {};
    Object.entries(fieldLabels).forEach(([fieldName, fieldLabel]) => {
      fieldLabelRecord[fieldName] = mapFieldLabelToString(fieldLabel);
    });
    reset(fieldLabelRecord);
    setInitialized(true);
  }, [fieldLabels, reset, initialized]);

  return (
    <form id={HEADER_NAMES_FORM_ID} onSubmit={handleSubmit(submit)}>
      <div className="flex flex-col space-y-4">
        <Button
          onClick={() => setConfirmResetOpen(true)}
          variant="outlined"
          disabled={isSubmitting || isLoading}
        >
          Reset to Default
        </Button>
        {columnNames.map((columnName) => (
          <FormGroup key={columnName}>
            <TextField label={columnName} {...register(columnName)} />
          </FormGroup>
        ))}
      </div>
      <ResetHeadersConfirmation
        isOpen={confirmResetOpen}
        setIsOpen={setConfirmResetOpen}
        submit={submit}
      />
    </form>
  );
};

export default TableHeaderConfigurator;
