import { useMutation } from "@tanstack/react-query";
import { saveHeaderNamesToTable } from "../api/headerName";
import { PartialFieldLabel } from "../pages/search/context/FieldLabelContext";
import { queryClient } from "../App";

export const getHeaderNamesQueryKey = (customerId: string, tableId: string) => [
  "columnNames",
  customerId,
  tableId,
];

export interface SaveHeaderNamesRequest {
  tableId: string;
  fieldLabels: Record<string, PartialFieldLabel>;
}

const updateQueryData = async (
  queryKey: string[],
  fieldLabels: Record<string, PartialFieldLabel>
) => {
  await queryClient.cancelQueries({
    queryKey,
  });

  queryClient.setQueryData(
    queryKey,
    (
      oldData: Map<string, PartialFieldLabel> | undefined
    ): Map<string, PartialFieldLabel> => {
      const newData = new Map(oldData);
      for (const fieldName in fieldLabels) {
        const fieldLabel = fieldLabels[fieldName];
        newData.set(fieldName, fieldLabel);
      }
      return newData;
    }
  );
};

export const useSaveHeaderNames = (customerId: string) => {
  return useMutation({
    mutationKey: ["saveColumnNames", customerId],
    mutationFn: async ({ tableId, fieldLabels }: SaveHeaderNamesRequest) => {
      return await saveHeaderNamesToTable(customerId, tableId, fieldLabels);
    },
    onMutate: async ({ tableId, fieldLabels }: SaveHeaderNamesRequest) => {
      const queryKey = getHeaderNamesQueryKey(customerId, tableId);
      await updateQueryData(queryKey, fieldLabels);
    },
  });
};
