import { PropsWithChildren } from "react";
import FieldLabelApiStorageProvider from "../../search/context/FieldLabelApiStorageProvider";
import SearchFieldLabelProvider from "../../search/context/SearchFieldLabelProvider";
import { SEARCH_TABLE_ID } from "../../search/Search";
import { useParams } from "react-router-dom";

const HeaderNameProviders = ({ children }: PropsWithChildren) => {
  const { customerId } = useParams();

  return (
    <FieldLabelApiStorageProvider customerId={customerId!}>
      <SearchFieldLabelProvider tableId={SEARCH_TABLE_ID}>
        {children}
      </SearchFieldLabelProvider>
    </FieldLabelApiStorageProvider>
  );
};

export default HeaderNameProviders;
