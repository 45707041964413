import { createContext } from "react";

export interface FieldLabel {
  defaultName: string;
  name: string;
}

export type PartialFieldLabel = Omit<FieldLabel, "defaultName">;

export interface IFieldLabelContext {
  columnNames: string[];
  saveFieldLabels: (
    fieldLabels: Record<string, PartialFieldLabel>
  ) => void | Promise<void>;
  fieldLabels: Record<string, FieldLabel>;
  defaultFieldLabels: Record<string, string>;
  getFieldLabel: (fieldName: string) => string;
  setFieldLabel: (field: string, label: string) => void;
}

const FieldLabelContext = createContext<IFieldLabelContext>({
  fieldLabels: {},
  defaultFieldLabels: {},
  columnNames: [],
  saveFieldLabels: () => {
    throw new Error("saveFieldLabels not implemented");
  },
  getFieldLabel: () => {
    throw new Error("getFieldLabel not implemented");
  },
  setFieldLabel: () => {
    throw new Error("setFieldLabel not implemented");
  },
});

export default FieldLabelContext;
