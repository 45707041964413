import IconButton from "@mui/material/IconButton";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Check from "@mui/icons-material/Check";
import { useCopyButton } from "./useCopyButton";

export interface CopyButtonProps {
  textToCopy: string;
  isVisible?: boolean;
}

export default function CopyButton({
  textToCopy,
  isVisible = false,
}: CopyButtonProps) {
  const { handleClick, isTooltipOpen } = useCopyButton(textToCopy, isVisible);

  if (!isVisible) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Typography variant="body2">
          <Check fontSize="small" /> Text copied
        </Typography>
      }
      placement="top"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={isTooltipOpen}
      arrow
    >
      <IconButton className="w-8 h-8" onClick={handleClick}>
        <CopyIcon color="primary" sx={{ fontSize: "16px" }} />
      </IconButton>
    </Tooltip>
  );
}
