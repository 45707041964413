import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import OpenableProps from "../../../helpers/openableProps";
import CopyButton from "../../clipboard/CopyButton";

export interface CellHoverMenuProps
  extends GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
    Omit<OpenableProps, "setIsOpen"> {}

export default function CellHoverMenu({
  isOpen,
  ...params
}: CellHoverMenuProps) {
  function textToCopy(): string {
    if (params.formattedValue != null) {
      return params.formattedValue;
    }

    if (params.colDef.valueGetter) {
      return params.row[params.colDef.field];
    }

    return params.value;
  }

  return (
    <div className={`w-auto flex-shrink-0 ${!isOpen && "hidden"}`}>
      <CopyButton textToCopy={textToCopy()} isVisible={isOpen} />
    </div>
  );
}
