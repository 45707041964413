import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  AutocompleteValue,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import { FieldValue } from "../../../api/interaction";
import { useTypeaheadSearch } from "../../../hooks/useInteraction";
import ListboxComponent from "../Virtualization/ListboxComponent";
import StyledPopper from "../Virtualization/StyledPopper";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

export interface TypeaheadProps<
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false
> extends Omit<
    AutocompleteProps<FieldValue, Multiple, DisableClearable, FreeSolo>,
    "onChange" | "value" | "renderInput" | "options"
  > {
  className?: string;
  label: string;
  name: string;
  pascalFieldName: string;
  value?: AutocompleteValue<FieldValue, Multiple, DisableClearable, FreeSolo>;
  onChange: (
    value: AutocompleteValue<FieldValue, Multiple, DisableClearable, FreeSolo>
  ) => void;
  customerId: string;
  virtualized?: boolean;
  TextFieldProps?: TextFieldProps;
  userInputValue?: string;
}

const filter = createFilterOptions<FieldValue>();

const Typeahead = <
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false
>({
  className = "",
  name,
  pascalFieldName,
  onChange,
  customerId,
  label,
  virtualized = false,
  TextFieldProps = {},
  userInputValue = "",
  ...props
}: TypeaheadProps<Multiple, DisableClearable, FreeSolo>) => {
  const [options, setOptions] = useState<FieldValue[]>([]);
  const [inputValue, setInputValue] = useState<string>(userInputValue);
  const { data, isLoading } = useTypeaheadSearch(
    name,
    pascalFieldName,
    inputValue,
    customerId
  );

  useEffect(() => {
    setOptions(data ?? []);
  }, [data]);

  return (
    <Autocomplete
      {...props}
      className={`min-w-48 ${className}`}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          {...TextFieldProps}
          label={label}
          InputProps={{
            ...TextFieldProps.InputProps,
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <LoadingIndicator size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(
        _: SyntheticEvent,
        value: AutocompleteValue<
          FieldValue,
          Multiple,
          DisableClearable,
          FreeSolo
        >
      ) => {
        if (typeof value === "string") {
          onChange({
            value,
            friendlyValue: value,
          } as AutocompleteValue<FieldValue, Multiple, DisableClearable, FreeSolo>);
          return;
        }
        onChange(value);
      }}
      options={options}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const filtered = filter(options, params);

        const isExisting = options.some(
          (option) => inputValue.toLowerCase() === option.value.toLowerCase()
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            value: inputValue,
            friendlyValue: inputValue,
          });
        }

        return filtered;
      }}
      inputValue={inputValue}
      onInputChange={(_: SyntheticEvent, value: string | null) => {
        setInputValue(value ?? "");
      }}
      getOptionLabel={(
        option: FieldValue | AutocompleteFreeSoloValueMapping<FreeSolo>
      ) => {
        if (typeof option === "string") {
          return option;
        }
        return option?.friendlyValue;
      }}
      isOptionEqualToValue={(option: FieldValue, value: FieldValue) =>
        option.value === value.value
      }
      PopperComponent={virtualized ? StyledPopper : undefined}
      ListboxComponent={virtualized ? ListboxComponent : undefined}
      loading={isLoading}
      size="small"
      disableCloseOnSelect={props.multiple}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

export default Typeahead;
