import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import FieldLabelContext, {
  FieldLabel,
  IFieldLabelContext,
  PartialFieldLabel,
} from "./FieldLabelContext";
import FieldLabelStorageContext from "./FieldLabelStorageContext";

const mergeFieldLabels = (
  fieldLabelMapping: Record<string, string | undefined>,
  storedFieldLabels: Map<string, PartialFieldLabel> | undefined
): Map<string, FieldLabel> => {
  const fieldLabels = new Map();
  for (const fieldName in fieldLabelMapping) {
    const fieldLabel = fieldLabelMapping[fieldName];
    const defaultName = fieldLabel ?? fieldName;
    const name = storedFieldLabels?.get(fieldName)?.name ?? defaultName;
    fieldLabels.set(fieldName, { defaultName, name });
  }
  for (const fieldName in storedFieldLabels) {
    const fieldLabel = storedFieldLabels?.get(fieldName);
    if (fieldLabel) {
      fieldLabels.set(fieldName, fieldLabel);
    }
  }
  return fieldLabels;
};

export interface FieldLabelProviderProps extends PropsWithChildren {
  labelMapping: Record<string, string>;
  tableId: string;
}

const FieldLabelProvider = ({
  children,
  labelMapping,
  tableId,
}: FieldLabelProviderProps) => {
  const { saveFieldLabelsToTable, useFieldLabelsByTable } = useContext(
    FieldLabelStorageContext
  );
  const { data: fieldLabelData } = useFieldLabelsByTable(tableId);
  const [fieldLabels, setFieldLabels] = useState<Map<string, FieldLabel>>(
    new Map()
  );

  useEffect(() => {
    if (!fieldLabelData) return;
    const mergedFieldLabels = mergeFieldLabels(labelMapping, fieldLabelData);
    setFieldLabels(mergedFieldLabels);
  }, [fieldLabelData, labelMapping]);

  const getFieldLabel = useCallback(
    (fieldName: string) => {
      return (
        fieldLabels.get(fieldName)?.name ??
        fieldLabels.get(fieldName)?.defaultName ??
        labelMapping[fieldName] ??
        fieldName
      );
    },
    [fieldLabels, labelMapping]
  );

  const setFieldLabel = useCallback(
    (field: string, fieldLabel: string) => {
      const defaultName = fieldLabels.get(field)?.defaultName;
      return setFieldLabels((prevFieldLabels) => {
        const newFieldLabels = prevFieldLabels.set(field, {
          defaultName: defaultName ?? field,
          name: fieldLabel,
        });
        return new Map(newFieldLabels);
      });
    },
    [fieldLabels]
  );

  const saveFieldLabels = useCallback(
    (fieldLabels: Record<string, PartialFieldLabel>) => {
      saveFieldLabelsToTable(tableId, fieldLabels);
    },
    [saveFieldLabelsToTable, tableId]
  );

  const contextValue = useMemo<IFieldLabelContext>(
    () => ({
      columnNames: Array.from(fieldLabels.keys()),
      saveFieldLabels,
      fieldLabels: Object.fromEntries(fieldLabels),
      defaultFieldLabels: labelMapping,
      getFieldLabel,
      setFieldLabel,
    }),
    [fieldLabels, saveFieldLabels, labelMapping, getFieldLabel, setFieldLabel]
  );

  return (
    <FieldLabelContext.Provider value={contextValue}>
      {children}
    </FieldLabelContext.Provider>
  );
};

export default FieldLabelProvider;
