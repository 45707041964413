import { createContext } from "react";
import { PartialFieldLabel } from "./FieldLabelContext";
import { UseQueryResult } from "@tanstack/react-query";

export interface IFieldLabelStorageContext {
  useFieldLabelsByTable: (
    tableId: string
  ) => UseQueryResult<Map<string, PartialFieldLabel> | undefined, Error>;
  saveFieldLabelsToTable: (
    tableId: string,
    fieldLabels: Record<string, PartialFieldLabel>,
    customerId?: string
  ) => void | Promise<void>;
}

const FieldLabelStorageContext = createContext<IFieldLabelStorageContext>({
  useFieldLabelsByTable: () => {
    throw new Error("useFieldLabelsByTable not implemented");
  },
  saveFieldLabelsToTable: () => {
    throw new Error("saveFieldLabelsToTable not implemented");
  },
});

export default FieldLabelStorageContext;
