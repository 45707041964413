import { Typography, CardContent } from "@mui/material";
import TableHeaderConfigurator, {
  HEADER_NAMES_FORM_ID,
} from "./TableHeaderConfigurator";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import FieldLabelContext from "../../search/context/FieldLabelContext";

const ConfigureHeaderNames = () => {
  const { defaultFieldLabels } = useContext(FieldLabelContext);
  const form = useForm({
    defaultValues: defaultFieldLabels,
  });
  const { formState } = form;
  const { isSubmitting, isLoading } = formState;
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <FormProvider {...form}>
      <div className="flex m-auto flex-col h-3/4">
        <Card className="flex flex-col">
          <CardHeader title="Configure Header Names" />
          <CardContent className="flex-1 overflow-y-auto">
            <Typography className="pb-2" variant="body1">
              Customize the names of the headers displayed in the search table
              for all users of this customer.
            </Typography>
            <TableHeaderConfigurator />
          </CardContent>
          <Box className="sticky bottom-0 bg-white h-fit z-50">
            <Divider />
            <Stack className="p-2" direction="row" spacing={2}>
              <Button
                form={HEADER_NAMES_FORM_ID}
                type="submit"
                variant="contained"
                disabled={isSubmitting || isLoading}
              >
                Save
              </Button>
              <Button onClick={goBack} color="secondary" variant="contained">
                Cancel
              </Button>
            </Stack>
          </Box>
        </Card>
      </div>
    </FormProvider>
  );
};

export default ConfigureHeaderNames;
