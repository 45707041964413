import { MouseEvent, useEffect, useState } from "react";
import { copyToClipboard } from "../../helpers/clipboard";

const TOOLTIP_TIMEOUT_SECONDS = 3;

export function useCopyButton(textToCopy: string, isVisible: boolean = true) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (isVisible) {
      return;
    }

    setIsTooltipOpen(false);
  }, [isVisible]);

  async function handleClick(
    event: MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    event.preventDefault();
    await copyToClipboard(textToCopy);
    showCopySuccessTooltip();
  }

  function showCopySuccessTooltip() {
    setIsTooltipOpen(true);

    setTimeout(() => {
      setIsTooltipOpen(false);
    }, TOOLTIP_TIMEOUT_SECONDS * 1_000);
  }

  return { handleClick, isTooltipOpen };
}
