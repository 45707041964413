import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@mui/material";

export interface RadioButtonsProps<TOption extends { value: any; label: string }> {
  label: string;
  value: any;
  onChange: (value: any) => void;
  options: TOption[];
  error?: boolean;
}

const RadioButtons = <TOption extends { value: any; label: string }>({
  label,
  value,
  onChange,
  options,
}: RadioButtonsProps<TOption>) => {
  return (
    <div>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        value={String(value)}
        aria-label={label}
        onChange={(e) => {
          const selectedOption = options.find(
            (option) => String(option.value) === e.target.value
          );
          if (selectedOption) {
            onChange(selectedOption.value);
          }
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={String(option.value)}
            value={String(option.value)}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};

export default RadioButtons;
