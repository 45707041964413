import { Outlet, createBrowserRouter } from "react-router-dom";
import AuditLogs from "../pages/audit-logs/AuditLogs";
import Connectors from "../pages/connectors/connectors";
import SetLogo from "../pages/customization/SetLogo";
import FederatedAuth from "../pages/federated-auth/FederatedAuth";
import Login from "../pages/login/Login";
import ResetPassword from "../pages/login/ResetPassword";
import SetPassword from "../pages/login/SetPassword";
import Roles from "../pages/roles/Roles";
import Search from "../pages/search/Search";
import SiteList from "../pages/sites/SiteList";
import UserList from "../pages/users/UserList";
import ErrorPage403 from "./403";
import ErrorPage from "./error-page";
import Root from "./root";
import AdminPortal from "../pages/admin-portal/AdminPortal";
import ErrorPage440 from "../pages/error/Error440";
import NewWindowMediaPlayer from "../components/media/NewWindowMediaDisplay";
import HeaderlessRoot from "./headerlessRoot";
import ConfigureHeaderNames from "../pages/customers/header-names/ConfigureHeaderNames";
import HeaderNameProviders from "../pages/customers/header-names/HeaderNameProviders";
import Dashboard from "../pages/dashboard/Dashboard";
import PermissionsRestrictedRoute from "./PermissionsRestrictedRoute";
import Home from "../pages/home/Home";

// All application routes will be defined here
const router = createBrowserRouter([
  {
    path: "/customers/:customerId/interactions/:playerInteractionId/:mediaType/:sourceIdLabel/:startTimeLabel/:endTimeLabel",
    element: (
      <HeaderlessRoot>
        <PermissionsRestrictedRoute>
          <Outlet />
        </PermissionsRestrictedRoute>
      </HeaderlessRoot>
    ),
    children: [
      {
        path: "",
        element: <NewWindowMediaPlayer />,
      },
    ],
  },
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "403",
        element: <ErrorPage403 />,
      },
      {
        path: "440",
        element: <ErrorPage440 />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "set-password",
        element: <SetPassword />,
      },
      {
        path: "reset-password",
        element: (
          <PermissionsRestrictedRoute>
            <ResetPassword />
          </PermissionsRestrictedRoute>
        ),
      },
      {
        path: "federated-auth",
        element: (
          <PermissionsRestrictedRoute
            resource="customization"
            requiredPermission="Read"
          >
            <FederatedAuth />
          </PermissionsRestrictedRoute>
        ),
      },
      {
        path: "/customers",
        element: <Outlet />,
        children: [
          {
            path: "/customers/:customerId",
            element: (
              <PermissionsRestrictedRoute>
                <Outlet />
              </PermissionsRestrictedRoute>
            ),
            children: [
              {
                index: true,
                element: <Home />
              },
              {
                path: "search",
                element: (
                  <PermissionsRestrictedRoute
                    resource="interactions"
                    requiredPermission="Read"
                  >
                    <Search />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "dashboard",
                element: (
                  <PermissionsRestrictedRoute
                    resource="interactions"
                    requiredPermission="Read"
                  >
                    <Dashboard />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "connectors",
                element: (
                  <PermissionsRestrictedRoute
                    resource="connectors"
                    requiredPermission="Read"
                  >
                    <Connectors />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "users",
                element: (
                  <PermissionsRestrictedRoute
                    resource="users"
                    requiredPermissions="Read"
                  >
                    <UserList />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "sites",
                element: (
                  <PermissionsRestrictedRoute
                    resource="sites"
                    requiredPermission="Read"
                  >
                    <SiteList />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "roles",
                element: (
                  <PermissionsRestrictedRoute
                    resource="roles"
                    requiredPermission="Read"
                  >
                    <Roles />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "audit-logs",
                element: (
                  <PermissionsRestrictedRoute
                    resource="auditlogs"
                    requiredPermission="Read"
                  >
                    <AuditLogs />
                  </PermissionsRestrictedRoute>
                ),
              },
              {
                path: "header-names",
                element: (
                  <HeaderNameProviders>
                    <Outlet />
                  </HeaderNameProviders>
                ),
                children: [
                  {
                    index: true,
                    element: <ConfigureHeaderNames />,
                  },
                ],
              },
            ],
          },
          {
            path: "/customers/admin",
            element: (
              <PermissionsRestrictedRoute>
                <AdminPortal />
              </PermissionsRestrictedRoute>
            ),
          },
        ],
      },
      {
        path: "customization",
        element: <Outlet />,
        children: [
          {
            path: "set-logo",
            element: <SetLogo />,
          },
        ],
      },
    ],
  },
]);

export default router;
