import { PartialFieldLabel } from "../pages/search/context/FieldLabelContext";
import { getApi, putApi } from "./helpers/apiMethods";

export const saveHeaderNamesToTable = async (
  customerId: string,
  tableId: string,
  headerNames: Record<string, PartialFieldLabel>
): Promise<void> => {
  await putApi(`/v1/customers/${customerId}/header-names/${tableId}`, {
    headerNames,
  });
};

export interface GetHeaderNamesResponse {
  customerId: string;
  tableId: string;
  headerNames: Record<string, PartialFieldLabel>;
}

export const getHeaderNamesFromTable = async (
  customerId: string,
  tableId: string
): Promise<Map<string, PartialFieldLabel> | undefined> => {
  console.log(customerId, tableId);
  const response = await getApi<GetHeaderNamesResponse>(
    `/v1/customers/${customerId}/header-names/${tableId}`
  );
  const headerNames = response.data?.headerNames;
  return headerNames ? new Map(Object.entries(headerNames)) : new Map();
};
