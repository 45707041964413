import { useContext } from "react";
import { AuthContext, IAuthContext } from "../../context/AuthContext";
import { Navigate } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import DefaultHomePage from "../default/DefaultHomePage";

const Home = () => {
  const { user, validating, authenticated } = useContext(
    AuthContext
  ) as IAuthContext;

  if (validating) {
    return <>Loading...</>;
  }

  if (!authenticated || !user) {
    return <Navigate to="/login" />;
  }

  return user.hasPermission(`interactions:Read`) ? (
    <Dashboard />
  ) : (
    <DefaultHomePage />
  );
};

export default Home;
