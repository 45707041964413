import { PropsWithChildren, useCallback, useMemo } from "react";
import { PartialFieldLabel } from "./FieldLabelContext";
import FieldLabelStorageContext, {
  IFieldLabelStorageContext,
} from "./FieldLabelStorageContext";
import {
  getHeaderNamesQueryKey,
  useSaveHeaderNames,
} from "../../../hooks/useHeaderName";
import { useQuery } from "@tanstack/react-query";
import { getHeaderNamesFromTable } from "../../../api/headerName";

export interface FieldLabelApiStorageProviderProps extends PropsWithChildren {
  customerId: string;
}

const FieldLabelApiStorageProvider = ({
  customerId,
  children,
}: FieldLabelApiStorageProviderProps) => {
  const { mutateAsync: saveColumnNames } = useSaveHeaderNames(customerId);

  const saveFieldLabelsToTable = useCallback(
    async (
      tableId: string,
      fieldLabels: Record<string, PartialFieldLabel>
    ): Promise<void> => {
      await saveColumnNames({ tableId, fieldLabels });
    },
    [saveColumnNames]
  );

  const contextValue = useMemo<IFieldLabelStorageContext>(
    () => ({
      useFieldLabelsByTable: (tableId: string) => {
        return useQuery({
          queryKey: getHeaderNamesQueryKey(customerId, tableId),
          queryFn: async () => {
            return await getHeaderNamesFromTable(customerId, tableId);
          },
          enabled: !!customerId && !!tableId,
        });
      },
      saveFieldLabelsToTable,
    }),
    [customerId, saveFieldLabelsToTable]
  );

  return (
    <FieldLabelStorageContext.Provider value={contextValue}>
      {children}
    </FieldLabelStorageContext.Provider>
  );
};

export default FieldLabelApiStorageProvider;
