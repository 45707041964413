import { SiteFormValues } from "../pages/sites/SiteForm";
import Site from "../types/site/Site";
import { getApi, postApi, putApi } from "./helpers/apiMethods";
import ApiErrors, { handleApiError } from "./helpers/errorHandling";

const path = "sites";

export type GetSitesResponse = {
  sites: Site[];
  errors: ApiErrors;
};

export type CreateSiteResponse = {
  site?: Site;
  errors: ApiErrors;
};

export type EditSiteResponse = {
  site?: Site;
  errors: ApiErrors;
};

export const getSite = async (
  customerId: string,
  siteId: string
): Promise<Site | undefined> => {
  const response = await getApi<Site>(
    `/v1/customers/${customerId}/${path}/${siteId}`
  );
  return response.data;
};

export const getSites = async (
  customerId: string
): Promise<GetSitesResponse> => {
  try {
    type Response = { sites: Site[] };
    const response = await getApi<Response>(
      `/v1/customers/${customerId}/${path}`
    );
    const sites: Site[] = response.data?.sites ?? [];
    return { sites, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errorMessage = handleApiError(error);
    return { sites: [], errors: errorMessage };
  }
};

export interface CreateSiteRequest extends SiteFormValues {
  customerId: string;
}

export const createSite = async ({
  customerId,
  ...request
}: CreateSiteRequest): Promise<CreateSiteResponse> => {
  try {
    const response = await postApi<Site>(
      `/v1/customers/${customerId}/sites`,
      request
    );
    const site: Site | undefined = response.data;
    return { site, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errors = handleApiError(error);
    return { errors };
  }
};

export interface EditSiteRequest extends SiteFormValues {
  customerId: string;
  siteId: string;
}

export const editSite = async ({
  customerId,
  siteId,
  ...request
}: EditSiteRequest): Promise<EditSiteResponse> => {
  try {
    const response = await putApi<Site, SiteFormValues>(
      `/v1/customers/${customerId}/sites/${siteId}`,
      request
    );
    const site: Site | undefined = response.data;
    return { site, errors: new ApiErrors() };
  } catch (error: unknown) {
    const errors = handleApiError(error);
    return { errors };
  }
};
