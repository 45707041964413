import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import { useState } from "react";
import { DataTableColDef } from "./DataTable";

export default function useTableCell(
  params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>,
  renderCell?: (
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
  ) => React.ReactNode
) {
  const [isHovered, setIsHovered] = useState(false);

  const showMenuOnHover =
    "showMenuOnHover" in params.colDef
      ? (params.colDef as DataTableColDef).showMenuOnHover ?? true
      : true;
  const shouldShowHoverMenu: boolean = isHovered && showMenuOnHover;

  function displayCellData() {
    if (renderCell) {
      return renderCell(params);
    }

    if (params.formattedValue != null) {
      return params.formattedValue;
    }

    if (params.colDef.valueGetter) {
      return params.row[params.colDef.field];
    }

    return params.value;
  }

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  return {
    handleMouseEnter,
    handleMouseLeave,
    displayCellData,
    isHoverMenuOpen: shouldShowHoverMenu,
  };
}
